import { addDoc, collection, deleteDoc, doc, Firestore, getDoc, getDocs, getFirestore, query, setDoc, where } from 'firebase/firestore'
import { getStorage, ref, listAll, getDownloadURL, getMetadata, deleteObject, uploadBytes, uploadBytesResumable } from "firebase/storage";
import { CHINELOS } from '../../constants/chinelos'
import { SANDALIAS } from '../../constants/sandalias'
import { SLIDERS } from '../../constants/sliders'

export const categories =[
    'chinelo',
    'slider',
    'sandalia'
]

//************* Public methods ***************/

export async function getProducts(category){
    return await getProductsFromFirebase2(category)
}

export async function getProduct(productId){
    return await getProductFromFirebase2(productId)
}

export async function save(product){
    if(!isValidProduct(product)) throw new Error('Invalid product')
    try{
        await saveOnFirebase(product)
        return true
    }catch(error){
        console.log(error)
        return false
    }
}

export async function deleteProduct(product){
    await deleteFromFirebase(product.id)
}


//************* Private methods **************/

function isValidProduct(product){
    if(!product) return false
    if(!isValidDescription(product.description)) return false
    if(!isValidCategory(product.category)) return false
    if(!isValidColors(product.colors)) return false
    return true
}

function isValidDescription(description){
    if(!description) return false
    return true
}

function isValidCategory(category){
    if(!category) return false
    return categories.includes(category)
}

function isValidColors(colors){
    if(!colors) return false
    if( colors.length <= 0 ) return false
    for( var color of colors){
        if(!isValidColor(color)) return false
    }
    return true
}

function isValidColor(color){
    if(!color) return false
    if(!color.color) return false
    if(!(color.image || color.file)) return false
    if(!color.image && !(color.file instanceof File || color.file instanceof Blob)) return false
    return true
}

async function getProductsFromLocalStorage(category){
    var products = []

    var chinelos = CHINELOS
    var sandalias = SANDALIAS
    var sliders = SLIDERS

    var data = [...chinelos, ...sandalias, ...sliders]

    data.forEach(prod =>{
        prod.colors.forEach(c => {
            var product = {
                id: prod.id ?? '',
                description: prod.description ?? '',
                category: prod.category ?? category,
                color: c.color ?? '',
                image: c.image ?? ''
            }
            products.push(product)
        })
    })

    return products.filter(prod => prod.category === category)
}

async function getProductsBaseFromFirebase(category){
    const db = getFirestore()
    const productsRef = collection(db, 'products')
    const q = category ? query(productsRef, where('category', '==', category)) : query(productsRef)

    const snapshot = await getDocs(q)

    var products = []

    snapshot.forEach(async doc =>{
        var _product = {
            id: doc.id ?? '',
            description: doc.data().description ?? '',
            category: doc.data().category ?? category,
            colors: []
        }
        products.push(_product)
    })

    return products
}

async function getProductColorsFromFirebase(productId){
    const db = getFirestore()
    const colorsRef = collection(db, 'products', productId, 'colors')
    const colorsSnapshot = await getDocs(colorsRef)
    var colors = []
    colorsSnapshot.forEach(colorDoc => {
        var color = {
            id: colorDoc.id,
            color: colorDoc.data().color ?? '',
            image: colorDoc.data().image ?? ''
        };
        colors.push(color);
    })
    return colors
}

async function getProductsFromFirebase2(category){
    
    var products = await getProductsBaseFromFirebase(category)

    for(var product of products){
        var colors = await getProductColorsFromFirebase(product.id)
        product.colors = colors
    }

    // console.log('products:')
    // console.log(products)
    return products
}

async function getProductsFromFirebase(category){
    const db = getFirestore()
    const productsRef = collection(db, 'products')
    const q = category ? query(productsRef, where('category', '==', category)) : query(productsRef)

    const snapshot = await getDocs(q)

    var products = []

    snapshot.forEach(doc =>{
        doc.data().colors.forEach(c => {
            var product = {
                id: doc.id ?? '',
                description: doc.data().description ?? '',
                category: doc.data().category ?? category,
                color: c.color ?? '',
                image: c.image ?? ''
            }
            products.push(product)
        })
    })

    return products
}

async function getProductFromFirebase2(productId){
    const db = getFirestore()
    const productRef = doc(db,`products/${productId}`)
    const product = await getDoc(productRef)
    var newProduct = {
        description: product.data().description,
        category: product.data().category,
        colors: []
    }
    const colorsRef = collection(db,productRef.path,'colors')
    var snapshot = await getDocs(colorsRef)
    snapshot.forEach(doc =>{
        let newColor = {
            id: doc.id,
            color: doc.data().color,
            image: doc.data().image
        }
        newProduct.colors.push(newColor)
    })
    return newProduct
}

async function getProductFromFirebase(productId){
    const db = getFirestore()
    const productRef = doc(db,`products/${productId}`)
    const product = await getDoc(productRef)
    return product.data()
}

async function saveProductBaseOnFirebase(product){
    const db = getFirestore()

    var productToSave = {
        description: product.description,
        category: product.category
    }
    
    if(product.id){
        const productRef = doc(db,'products',product.id)
        await setDoc(productRef, productToSave)
        productToSave.id = product.id
    }else{
        const productsRef = collection(db, 'products')
        const docRef = await addDoc(productsRef, productToSave)
        productToSave.id = docRef.id
    }
    return productToSave
}

async function getDeletedColors(product){
    if(!product.id) throw new Error('need product id')
    var deletedColors = []
    var oldColors = await getProductColorsFromFirebase(product.id)
    if(!oldColors || oldColors.length <=0 ) return deletedColors
    if(!product.colors) return oldColors
    for(var oldColor of oldColors){
        var notDeletedColor = product.colors.find(color => color.id === oldColor.id)
        if(!notDeletedColor) deletedColors.push(oldColor)
    }
    return deletedColors
}

async function saveColorsOnFirebase(productId, colors){
    const db = getFirestore()
    const colorsRef = collection(db, 'products', productId, 'colors')
    console.log(colors)
    for(var color of colors){
        console.log(color)
        if(color.id){
            if(color.file){
                var url = await saveColorImg(color.file, color.id)
                const colorRef = doc(db,'products',productId,'colors',color.id)
                await setDoc(colorRef,{color: color.color, image: url})
            }else{
                const colorRef = doc(db,'products',productId,'colors',color.id)
                await setDoc(colorRef,{color: color.color, image: color.image})
            }
        }else{
            if(color.file){
                var colorDoc = await addDoc(colorsRef,{color: color.color})
                var url = await saveColorImg(color.file, colorDoc.id)
                const colorRef = doc(db,'products',productId,'colors',colorDoc.id)
                await setDoc(colorRef,{color: color.color, image: url})
            }else{
                var colorDoc = await addDoc(colorsRef,{color: color.color, image:color.image})
            }
        }
    }
}

async function deleteColorsOnFirebase(productId, colors){
    const db = getFirestore()
    for(let color of colors){
        await deleteColorImage(color.id)
        const docRef = doc(db, 'products', productId, 'colors', color.id)
        await deleteDoc(docRef)
    }
}

async function saveOnFirebase(product){

    var newProduct = await saveProductBaseOnFirebase(product)

    if(product.id){

        var deletedColors = await getDeletedColors(product)

        if( deletedColors && deletedColors.length > 0 ) await deleteColorsOnFirebase(product.id, deletedColors)
    }

    await saveColorsOnFirebase(newProduct.id, product.colors)

    return true

}

async function deleteFromFirebase(productId){
    const db = getFirestore()
    var colors = await getProductColorsFromFirebase(productId)
    await deleteColorsOnFirebase(productId, colors)
    await deleteDoc(doc(db, 'products', productId))
}

async function saveColorImg(file, name){
    const storage = getStorage()
    const bannerPathRef =  `products/${name}`
    const bannerRef = ref(storage,bannerPathRef)
    var snapshot = await uploadBytesResumable(bannerRef,file)
    var url = await getDownloadURL(snapshot.ref)
    return url
}

async function saveColorImage(file){
    const storage = getStorage()
    const bannerPathRef =  `products/${file.name}`
    const bannerRef = ref(storage,bannerPathRef)
    var result = uploadBytesResumable(bannerRef,file)
    result.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    },
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    },
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(result.snapshot.ref).then((downloadURL) => {
        console.log('File available at', downloadURL);
      });
    }
  );
}

async function deleteColorImage(name){
    const storage = getStorage()
    const imageRef = ref(storage, `products/${name}`)
    await deleteObject(imageRef)
    return true
}
