import styles from './index.module.css'

export default function Input({className, ...props}){

    return(
        <div className={styles.FormInput + ' ' + className}>
            <label>{props.label}</label>
            <input {...props} />
        </div>
        
    )
}