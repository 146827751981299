import styles from './index.module.css'

export default function ToggleButton(props){
    function onChange(e){
        props.onChange(e.target.checked)
    }
    return(
        <label class={styles.switch}>
            <input type="checkbox" onChange={onChange} checked={props.value}/>
            <span class={styles.slider}></span>
        </label>
    )
}