export const CHINELOS = [
    {
        id: 'chinelo1',
        category: 'chinelo',
        description: 'Cabedal 105 / Sola ARZ02 EXP / Palmilha Santorini ARZ02 confort',
        colors: [
            {
                color: 'PRETO',
                image: '/assets/products/CHINELO/DSC_0264.JPG'

            },
            {
                color: 'BRANCO',
                image: '/assets/products/CHINELO/DSC_0271.JPG'

            },
            {
                color: 'BEGE',
                image: '/assets/products/CHINELO/DSC_0268.JPG'

            },
            {
                color: 'CARAMELO',
                image: '/assets/products/CHINELO/DSC_0263.JPG'

            },
            {
                color: 'ROSÊ',
                image: '/assets/products/CHINELO/DSC_0265.JPG'

            },
            {
                color: 'AMARELO BEBÊ',
                image: '/assets/products/CHINELO/DSC_0267.JPG'

            },
            {
                color: 'VERDE ABACATE',
                image: '/assets/products/CHINELO/DSC_0262_1.jpeg'

            },
            {
                color: 'LILÁS',
                image: '/assets/products/CHINELO/DSC_0272.JPG'

            },
            {
                color: 'VERMELHO',
                image: '/assets/products/CHINELO/DSC_0266.JPG'

            },
        ]
    }
]