import { useEffect, useRef, useState } from 'react'
import styles from './EditProductComponent.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'
import { deleteProduct, getProduct, save } from '../../../../services/products/product.service'
import Loading from '../../../../components/LoadingDots/LoadingDots.module'
import { maxProductImageSize } from '../../../../constants/maxImageSizes'

export function EditProductComponent(props){

    const urlParams = useParams()
    const [product, setProduct] = useState(null)
    const [colorSelected, setColorSelected] = useState(null)
    const [updating, setUpdating] = useState(true)

    const imageInputRef = useRef(null)

    useEffect(() => {
        if(urlParams.productId){
            // setUpdating(true)
            getProduct(urlParams.productId)
            .then(product =>{
                var newProduct = {
                    id: urlParams.productId,
                    description: product.description,
                    category: product.category,
                    colors: []
                }
                if(product.colors){
                    for(let color of product.colors){
                        newProduct.colors.push(
                            {
                                id: color.id,
                                title: color.color,
                                downloadUrl: color.image
                            }
                        )
                    }
                }
                setProduct(newProduct)
            }).finally(()=>{
                setUpdating(false)
            })
        }else{
            setProduct({
                id: null,
                description: '',
                category: '',
                colors:[]
            })
            setUpdating(false)
        }
        
    },[urlParams.productId])

    function descriptionChanged(e){
        var newProduct = {...product}
        newProduct.description = e.target.value
        setProduct(newProduct)
    }

    function onSave(){

        setUpdating(true)
        var p = convertProduct(product)
        console.log(p)

        save(p)
        .then(result =>{
            alert('Salvo com sucesso!!!')
            window.history.back()
        })
        .catch(error =>{
            alert('Não foi possível salvar o produto. Verifique se todos os campos estão preenchidos.')
        }).finally(()=>{
            setUpdating(false)
        })

    }

    function onDelete(){
        setUpdating(true)
        var response = window.confirm('Tem certeza que deseja excluir o produto?')
        if(!response) { setUpdating(false); return; }
        try{
            deleteProduct(product).then(()=>{
                alert('Deletado!')
                window.history.back()
            })
        }catch(error){
            alert('Error ao deletar')
        }finally{
            setUpdating(false)
        }
    }

    function onCancel(){
        window.history.back()
    }

    function convertProduct(_product){
        var newProduct = { colors: [] }
        if(_product.id) newProduct.id = _product.id
        newProduct.description = _product.description ?? ''
        newProduct.category = _product.category ?? ''
        if(_product.colors){
            for(var color of _product.colors){
                let newColor = convertColor(color)
                newProduct.colors.push(newColor)
            }
        }
        return newProduct
    }

    function convertColor(color){
        var newColor = {
            id: color.id,
            color: color.title ?? color.color
        }
        if(color.imageFile){
            newColor.file = color.imageFile
        }else{
            newColor.image = color.downloadUrl
        }
        return newColor
    }

    function addNewColor(){
        // setEditColorActive(true)
        // return
        if(product){
            if(!product.colors){
                let colors = []
                colors.push({
                    color: '',
                    image: ''
                })
                let newProduct = {...product, colors: colors}
                setProduct(newProduct)
            }else{
                let newProduct = {...product}
                newProduct.colors.push({
                    color: '',
                    image: ''
                })
                setProduct(newProduct)
            }
        }else{
            console.log('nao tem product')
        }
    }

    function deleteColor(index){
        let newProduct = {...product}
        newProduct.colors.splice(index, 1)
        setProduct(newProduct)
        onColorSelected(0)
    }

    function colorTitleChanged(index, colorName){
        let newProduct = {...product}
        newProduct.colors[index].title = colorName
        setProduct(newProduct)
        onColorSelected(index)
    }

    function colorImageChanged(index, colorImageFile){
        // if(!colorImageFile) return
        if(colorImageFile && colorImageFile.size > maxProductImageSize){
            alert(`Imagem muito grande. Selecione uma imagem menor.\nTamanho máximo permitido: ${(maxProductImageSize/1024).toFixed(1)} kbytes \nTamanho da imagem selecionada: ${(colorImageFile.size / 1024).toFixed(1)} kbytes`)
            return
        }
        let newProduct = {...product}
        newProduct.colors[index].imageFile = colorImageFile
        setProduct(newProduct)
        onColorSelected(index)
    }

    function onColorSelected(index){
        if( !product ||
            !product.colors || 
            product.colors.length <= 0 || 
            product.colors.length <= index
        ) 
        {
            setColorSelected(null)
            return
        }
        setColorSelected(product.colors[index])
    }

    function onCategoryChanged(e){
        let newProduct = {...product}
        newProduct.category = e.target.value
        setProduct(newProduct)
    }

    return(
        <div className={`${styles.EditProductComponent}`}>
            <UpdatingFrame show={updating} />
            <div className={styles.EditProductComponentImageAndDescription} >
                <input ref={imageInputRef} type='file' className={styles.EditProductComponentImageInput} accept="image/*" /*onChange={onImageSelected}*//>
                <button className={styles.EditProductComponentImageButton} >
                    {
                        colorSelected ? 
                            colorSelected.imageFile?
                                <img className={styles.EditProductComponentImage} src={URL.createObjectURL(colorSelected.imageFile)} alt='' /> :
                                    colorSelected.downloadUrl?
                                    <img className={styles.EditProductComponentImage} src={colorSelected.downloadUrl} alt='' />
                                    :<FontAwesomeIcon icon={faImage} size='10x' color='#8c8c8c'/>
                        :<FontAwesomeIcon icon={faImage} size='10x' color='#8c8c8c'/>
                    }
                </button>
                <textarea className={styles.EditProductComponentDescriptionInput} value={product? product.description ?? '' : ''} onChange={descriptionChanged} placeholder='Insira uma descrição para o produto...' />
            </div>
            <div className={styles.EditProductCategoriesContent} onChange={onCategoryChanged}>
                <div>
                <input type='radio' id='chinelo' name='productCategory' value='chinelo' checked={product && product.category && product.category === 'chinelo'} />
                <label for='chinelo'>Chinelo</label>
                </div>
                <div>
                <input type='radio' id='sandalia' name='productCategory' value='sandalia' checked={product && product.category && product.category === 'sandalia'} />
                <label for='sandalia'>Sandália</label>
                </div>
                <div>
                <input type='radio' id='slider' name='productCategory' value='slider' checked={product && product.category && product.category === 'slider'} />
                <label for='slider'>Slider</label>
                </div>
            </div>
            <div className={styles.EditProductComponentColorsContent}>
                <h2>Cores:</h2>
                {
                    product && product.colors && product.colors.length > 0 ? 
                    product.colors.map((colorItem, index) => <ColorItem key={index} index={index} color={colorItem} onColorTitleChanged={colorTitleChanged} onImageChanged={colorImageChanged} onColorSelected={onColorSelected} onDelete={deleteColor} />) : 
                    <NoColorsFound />
                }
                <button id={styles.EditProductComponentAddNewColorButton} className={styles.TertiaryButton} onClick={addNewColor}>Adicionar nova cor</button>
            </div>
            <div className={styles.EditProductComponentSaveCancelButtons}>
                <button className={styles.SecondaryButton} onClick={onCancel}>Cancelar</button>
                { product && product.id ? <button id={styles.EditProductComponentDeleteButton} className={styles.TertiaryButton} onClick={onDelete}>Deletar</button> : null }
                <button id={styles.EditProductComponentSalvarButton} className={styles.PrimaryButton} onClick={onSave}>Salvar</button>
            </div>
            
        </div>
    )
}

function ColorItem(props){

    const imageInputRef = useRef(null)

    function onColorTitleChanged(e){
        props.onColorTitleChanged(props.index, e.target.value)
    }

    function onDelete(){
        props.onDelete(props.index)
    }

    function onColorItemClicked(){
        props.onColorSelected(props.index)
    }

    function changeImage(){
        imageInputRef.current.click()
    }

    // function removeImage(){
    //     props.onImageChanged(props.index, null)
    // }

    function onImageSelected(e){
        let file = e.target.files[0]
        if(file) props.onImageChanged(props.index, file)
    }

    return (
        <div className={ styles.EditProductComponentColorItem} onClick={onColorItemClicked}>
            <input ref={imageInputRef} type='file' accept="image/*" onChange={onImageSelected} style={{display: 'none'}}/>
            
            <button className={styles.EditProductComponentColorItemImageIcon} onClick={changeImage}>
                {
                    props.color.imageFile ?
                    <img src={URL.createObjectURL(props.color.imageFile)} alt='' /> :
                    props.color.downloadUrl ?
                    <img src={props.color.downloadUrl} alt='' /> :
                    <FontAwesomeIcon icon={faImage} size='3x' color={props.color.imageFile ? 'var(--main-red)' : '#8c8c8c'}/>
                }
            </button>
            <input type='text' value={props.color.title} onChange={onColorTitleChanged}/>
            <button className={styles.EditProductComponentColorItemCloseIcon} onClick={onDelete}>
                <FontAwesomeIcon icon={faPlusCircle} color='var(--main-red)'/>
            </button>
        </div>
    )
}

function NoColorsFound(){
    return(
        <div className={styles.EditProductComponentNoColorsFound}>
            Nenhuma cor
        </div>
    )
}

function UpdatingFrame(props){
    return(
        <div className={styles.updatingFrame} style={{display: props.show ? 'flex' : 'none'}}>
            <Loading />
        </div>
    )
}