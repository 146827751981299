import styles from './skeleton.module.css'

export function Skeleton(props){
    return (
        <div className={`${styles.skeletonBox} ${props.className}`} >
            <div className={styles.skeletonLight}></div>
        </div> 
    )
}

export default Skeleton