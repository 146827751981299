import { useRef } from 'react'
import styles from './productsConfiguration.module.css'

export function ProductsConfiguration(props){

    const ProductsConfigurationRef = useRef(null)

    function EditProduct(product){
        window.location.assign('/edit-product/' + product.id)
    }

    return(
        <div ref={ProductsConfigurationRef} className={styles.ProductsConfiguration}>
            <div className={styles.ProductsConfigurationContent}>
                {/* {editProduct ? <EditProductComponent product={editProduct} onSave={SaveProduct} onCancel={CancelEditProduct} /> : null} */}
                {props.products.map((product,index) => <ProductsConfigurationItem key={index} product={product} onProductClick={EditProduct}/>)}
            </div>
        </div>
    )
}

function ProductsConfigurationItem(props){
    console.log(props.product)
    function onClick(){
        props.onProductClick(props.product)
    }
    return(
        <button className={styles.ProductsConfigurationItem} onClick={onClick}>
            <img src={props.product.colors[0].image} alt=''/>
            <span>{`${props.product.description}`}</span>
        </button>
    )
}