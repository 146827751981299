import styles from './index.module.css'
import backgroundTop from '../../assets/images/produtos/backgroundTop.png'
import chinelosImage from '../../assets/images/chinelos.png'
import sliderImage from '../../assets/images/slider.png'
import sandaliaImage from '../../assets/images/sandalia.png'
import facebookImage from '../../assets/images/facebookIcon.png'
import instagramImage from '../../assets/images/InstagramIcon.png'
import Links from '../../constants/socialMedias'
import FLogo from '../../assets/images/produtos/FormulaFLogoBottom.png'

export default function Produtos(props){
    return(
        <div className={styles.ProdutosPage}>
            <h1>PRODUTOS</h1>
            <img className={styles.ProdutosPageBackgroundTop} src={backgroundTop} alt=''/>
            <div className={styles.ProdutosPageContent}>
                <div className={styles.ProdutosPageCategoryItems}>
                    <ProdutoItem imageSrc={sandaliaImage} label='SANDÁLIAS' href='produtos/sandalia'/>
                    <ProdutoItem imageSrc={chinelosImage} label='CHINELOS' href='produtos/chinelo'/>
                    <ProdutoItem imageSrc={sliderImage}  label='SLIDERS' href='/produtos/slider'/>
                </div>
                <p>Nossa qualidade ímpar e preocupação em sempre trazer as maiores tendências e inovação em tecnologia de ponta, faz com que os materiais injetados de Injetados Formula se destaquem no mercado</p>
            </div>
            <footer>
                <div className={styles.ProdutosCategoryFooterSocialMidias}>
                    <h3>CONHEÇA MAIS:</h3>
                    <div className={styles.ProdutosPageSocialMidiaIcons}>
                    <a href={Links.facebook}>
                        <img src={facebookImage} alt='facebook'/>
                    </a>
                    <a href={Links.instagram}>
                        <img src={instagramImage} alt='instagram'/>
                    </a>
                    </div>
                </div>
                <img className={styles.FLogo} src={FLogo} alt=''/>
            </footer>
        </div>
    )
}

function ProdutoItem(props){
    return(
        <a className={styles.ProdutosPageCategoryItem}
        href={props.href}>
            <img src={props.imageSrc} alt='' />
            <h2>{props.label}</h2>
            <p>(clique aqui)</p>
        </a>
    )
}