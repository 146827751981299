import styles from './index.module.css'
import circuloSup from '../../assets/images/maintenance/CIRCULO 01.png'
import circuloInf from '../../assets/images/maintenance/CIRCULO 02.png'
import warnImage from '../../assets/images/maintenance/ATENÇÃO.png'
import instagramImage from '../../assets/images/maintenance/INSTAGRAM.png'
import facebookImage from '../../assets/images/maintenance/FACEBOOK.png'
import logoImage from '../../assets/images/maintenance/LOGO.png'
function MaintenancePage(){
    return (
        <div class={styles.Container}>
          <img id={styles.circuloSup} src={circuloSup} alt=''/>
          <img id={styles.circuloInf} src={circuloInf} alt=''/>
            <img id={styles.warnImage} src={warnImage}  alt='Warn' />
            <div id={styles.desculpe} >DESCULPE O TRANSTORNO</div>
            <div id={styles.trabalhando}>ESTAMOS TRABALHANDO NESTE SITE</div>
            <div id={styles.icons}>
            <a class={styles.icon} href='https://www.instagram.com/injetadosformula/'>
                <img class={styles.iconImage} src={instagramImage} alt='visite nosso instagram'/>
            </a>
            <a class={styles.icon} href='https://www.facebook.com/formulainjetados/'>
                <img class={styles.iconImage} src={facebookImage} alt='visite nosso facebook'/>
            </a>
            </div>
            <img id={styles.logo} src={logoImage} alt='Injetados Fórmula Logo'/>
        </div>
    )
}      
        
export default MaintenancePage