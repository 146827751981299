import styles from './BannerConfiguration.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'

export function BannerConfiguration(props){

    function onDeleteClick(banner){
        props.onDelete(banner)
    }
    
    return (
        <div className={styles.BannersConfiguration}>
            {props.banners.map((banner,index) => <BannerItem key={index} banner={banner} onDeleteClick={onDeleteClick} />)}
        </div>
    )
}

function BannerItem(props){

    function onDeleteClick(){
        props.onDeleteClick(props.banner)
    }

    return(
        <div className={styles.BannerConfigurationItem} >
            <button onClick={onDeleteClick}><FontAwesomeIcon icon={faXmarkCircle} size='xl' /></button>
            <div className={styles.BannerItemTitle}>{props.banner.name ?? 'Nome não encontrado...'}</div>
            <img src={props.banner.url} alt='' />
        </div>
    )
}