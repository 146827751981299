import {initializeApp} from 'firebase/app'

const firebaseConfig = {
    apiKey: "AIzaSyDWq5dJZ5pwGsMksUMl3OAPz4ui9PyPwOM",
    authDomain: "formula-injetados.firebaseapp.com",
    projectId: "formula-injetados",
    storageBucket: "formula-injetados.appspot.com",
    messagingSenderId: "856015402770",
    appId: "1:856015402770:web:7132b78dcc71f7dd1ea5cb",
    measurementId: "G-P06CCJKWGP"
};

export function initFirebaseApp(){

    initializeApp(firebaseConfig);

}
