import styles from './index.module.css'
import TopBarMenu from "../../components/topBar";
import HomeBanner from "./components/HomeBanner";
import Slogan from './components/Slogan';
import Bottom from './components/Bottom';
import Footer from '../../components/Footer';

export default function Home(props){
    return(
        <div className={styles.HomePage}>
            <TopBarMenu />
            <HomeBanner />
            <Slogan />
            <Bottom />
            <Footer />
        </div>
    )
}