import { EditProductComponent } from '../components/EditProductComponent/EditProductComponent.module'
import styles from './productEditPage.module.css'

export function ProductEditPage(props){
    return (
        <div className={styles.ProductEditPage}>
            <div className={styles.PtoductEditPageContent}>
                <EditProductComponent />
            </div>
        </div>
    )
}