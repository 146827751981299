import styles from './index.module.css'
import facebookIcon from '../../assets/images/facebookIcon.png'
import instagramIcon from '../../assets/images/InstagramIcon.png'
import links from '../../constants/socialMedias'

export default function Footer(){
    return(
        <div className={styles.Footer}>
            <div id={styles.leftSpace}></div>
            <p>Todos os direitos reservados</p>
            <div className={styles.SocialMedias}>
                <a href={links.facebook} className={styles.SocialMedia}>
                    <img className={styles.SocialMediaImage} src={facebookIcon} alt='facebook'/>
                </a>
                <a href={links.instagram} className={styles.SocialMedia}>
                    <img className={styles.SocialMediaImage} src={instagramIcon} alt='instagram'/>
                </a>
            </div>
        </div>
    )
}