import styles from './index.module.css'

export default function TextArea(props){
    return(
        <div className={styles.FormTextArea + ' ' + props.className}>
            <label>{props.label}</label>
            <textarea {...props} />
        </div>
        
    )
}