import styles from './index.module.css'
import backgroundBottomTop from '../../../../assets/images/empresa/backgroundBottom.png'
import Input from '../../../../components/FormInput'
import TextArea from '../../../../components/FormTextArea'
import facebookIcon from '../../../../assets/images/facebookIcon.png'
import instagramIcon from '../../../../assets/images/InstagramIcon.png'
import Links from '../../../../constants/socialMedias'
import { enderecoLink } from '../../../../constants/endereco'
import { phoneLink, mailLink, comercialPhoneLink } from '../../../../constants/contatos'
import { sendEmail } from '../../../../services/sendMessage/sendMessage.service'
import { useState } from 'react'

export default function ContatoComponent(){

    const [from_name, set_from_name] = useState('')
    const [from_email, set_from_email] = useState('')
    const [message, setMessage] = useState('')

    function isEmailValid(value) {
        let usuario = value.substring(0, value.indexOf("@"));
        let dominio = value.substring(value.indexOf("@")+ 1, value.length);
        
        return !!((usuario) &&
            (dominio) &&
            (usuario.length >=1) &&
            (dominio.length >=3) &&
            (usuario.search("@")===-1) &&
            (dominio.search("@")===-1) &&
            (usuario.search(" ")===-1) &&
            (dominio.search(" ")===-1) &&
            (dominio.search(".")!==-1) &&
            (dominio.indexOf(".") >=1)&&
            (dominio.lastIndexOf(".") < dominio.length - 1));
    }

    function send(){
        try{
            if(!from_name){
                alert('Informe um nome!')
                return
            }

            if(!from_email){
                alert('Informe um email!')
                return
            }

            if(!message){
                alert('Escreva uma mensagem!')
                return
            }

            if(!isEmailValid(from_email)){
                alert('Email inválido! Informe um email válido!')
                return;
            }
            
            
            var response = sendEmail(from_email, from_name, message)
            if(response) alert('Mensagem enviada com sucesso!')
            else alert('Erro ao enviar mensagem. Contacte pelos meios abaixo.')
            setMessage('')
            set_from_name('')
            set_from_email('')
        }catch(error){
            alert('Erro ao enviar mensagem. Contacte pelos meios abaixo.')
        }
    }

    function onNameChange(e){
        set_from_name(e.target.value)
    }

    function onEmailChange(e){
        set_from_email(e.target.value)
    }

    function onMessageChange(e){
        setMessage(e.target.value)
    }

    return(
        <div className={styles.ContatoComponent}>
            <h1>Deixe um Recado</h1>
            
            <img className={styles.backgroundContatoBottomTop} src={backgroundBottomTop} alt=''/>

            <div className={styles.ContatoContent}>
                <div className={styles.ContatoForm}>
                    <Input className={styles.ContatoFormItem} type='text' label='NOME:' value={from_name} onChange={onNameChange}/>
                    <Input className={styles.ContatoFormItem} type='email' label='EMAIL:' value={from_email} onChange={onEmailChange}/>
                    <TextArea className={styles.ContatoFormItem} type='text'  label='MENSAGEM:'
                    rows='10' onChange={onMessageChange} value={message}
                    />
                    <button className={styles.ContatoFormSendButton} onClick={send}>ENVIAR</button>
                </div>
                <div className={styles.ContatoEnderecoContent}>
                    <div className={styles.ContatoList}>
                        <p><span>CONTATO:</span><a href={phoneLink}>(37)3226-0828</a></p>
                        <p><span>COMERCIAL:</span><a href={comercialPhoneLink}>(37)99135-0989</a></p>
                        <a href={mailLink}><p>pedidos@injetadosformula.com.br</p></a>
                    </div>
                    <a className={styles.EnderecoList} href={enderecoLink}>
                        <p><span>ENDEREÇO:</span> Rua Hum, 668 - Subsolo</p>
                        <p>Prol. Amaral - Nova Serrana | MG</p>

                    </a>
                </div>
                <div className={styles.ContatoSocialMidia}>
                    <h3>FIQUE CONECTADO:</h3>
                    <a href={Links.facebook}>
                        <img src={facebookIcon} alt=''/>
                    </a>
                    <a href={Links.instagram}>
                        <img src={instagramIcon} alt=''/>
                    </a>
                </div>
            </div>
        </div>
    )
}