import styles from './index.module.css';
import Logo from '../../assets/images/MiniLogoFormula.png'

export function TopBarMenu(){
    return(
        <div className = {styles.topBar}>
            <img className={styles.logo} src={Logo} alt="Logo Injetados Formula" />
            <div className={styles.menuItems}>
                <a className={styles.menuItem} href='/'>HOME</a>
                <a className={styles.menuItem} href='/empresa'>EMPRESA/CONTATO</a>
                <a className={styles.menuItem} href='/produtos'>PRODUTOS</a>
            </div>
            
        </div>
    )
}

export default TopBarMenu