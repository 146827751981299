import styles from './internoConfigurationPage.module.css'
import { useEffect, useRef, useState } from 'react'
import { getBanners as getBannersService, deleteBanner, uploadBanner } from '../../../services/bannerImages/bannerImages.service'
import ConfigurationSession from '../components/ConfigurationSession/configurationSession.module'
import { BannerConfiguration } from './BannerConfiguration/BannerConfiguration.module'
import { getProducts as getProductsService } from '../../../services/products/product.service'
import { ProductsConfiguration } from './ProductsConfiguration'
import { Skeleton } from '../../../components/Skeleton'
import { logout as authLogout } from '../../../services/auth/auth.module'
import { maxBannerImageSize } from '../../../constants/maxImageSizes'
import ToggleButton from '../../../components/ToggleButton'
import { setMaintenance } from '../../../services/maintenance/maintenance.service'
import { doc, getFirestore, onSnapshot } from 'firebase/firestore'

export default function InternoConfigurationPage(props){

    const addBannerInputRef = useRef(null)
    const [banners, setBanners] = useState([])
    const [products, setProducts] = useState([])
    const [viewProducts, setViewProducts] = useState([])
    const [productsCategory, setProductsCategory] = useState('')
    const [bannersLoading, setBannersLoading] = useState(false)
    const [productsLoading, setProductsLoading] = useState(false)

    useEffect(()=>{
        getBanners()
        getProducts()
    },[])

    useEffect(()=>{
        updateViewProducts(products)
    },[products, productsCategory])

    function logout(){
        authLogout()
    }

    function updateViewProducts(products){
        var newViewProducts = products.filter(product => {
            return (productsCategory === '') || (product.category === productsCategory)
        })
        setViewProducts(newViewProducts)
    }

    function getProducts(){
        setProductsLoading(true)
        getProductsService().then(newProducts =>{
            setProducts(newProducts)
            setProductsLoading(false)
        }).catch(() => {
            setProductsLoading(false)
        })
    }

    function getBanners(){
        setBannersLoading(true)
        getBannersService().then(newBanners =>{
            setBanners(newBanners)
            setBannersLoading(false)
        }).catch(() => {
            setBannersLoading(false)
        })
    }

    function onBannerAddClick(){
        addBannerInputRef.current.click()
    }

    async function onDeleteBanner(banner){
        const response = window.confirm(`Tem certeza que deseja deletar o banner ${banner.name}?`)
        if(!response) return
        const result = await deleteBanner(banner.name)
        if(!result) { alert('Não foi possível deletar o banner'); return;}
        alert('Banner deletado com sucesso')
        getBanners()
    }

    function onProductAddClick(){
        window.location.assign('/edit-product')
    }

    async function onAddBannerInputChanged(event){
        var imageBanner = event.target.files[0]
        if( imageBanner ){
            if(imageBanner.size > maxBannerImageSize){
                alert(`Imagem muito grande. Selecione uma imagem menor.\nTamanho máximo permitido: ${(maxBannerImageSize/1024).toFixed(1)} kbytes \nTamanho da imagem selecionada: ${(imageBanner.size / 1024).toFixed(1)} kbytes`)
                return
            }
            setBannersLoading(true)
            const result = await uploadBanner(imageBanner)
            if(result){ 
                getBanners()
                alert('O banner foi salvo!');
                setBannersLoading(false)
                return;
            }
            alert('Houve um problema ao salvar o banner. Tente novamento em alguns instantes.')
            setBannersLoading(false)
        }
        console.log('Nenhum arquivo para ser salvo')
    }

    function onProductCategoryChange(category){
        setProductsCategory(category)
    }

    function maintenanceChange(value){
        setMaintenance(value)
    }

    const [inMaintenance, setInMaintenance] = useState(false)
    useEffect(()=>{
        const db = getFirestore()
        onSnapshot(doc(db,'config','maintenance'), (document)=>{
        var data = document.data()
        setInMaintenance(data.inMaintenance)
        })
    })

    return(
        <div className={styles.InternoConfigurationPage}>
            <TopBar title='Injetados Fómula' logout={logout} />
            <div style={{width:'100%', display:'flex', flexDirection:'row', alignItems:'center'}}>
                <h4 style={{marginLeft:'50px', marginRight:'20px'}}>Em Manutenção </h4>
                <ToggleButton onChange={maintenanceChange} value={inMaintenance}/>
            </div>
            <ConfigurationSession title='Banners' addClick={onBannerAddClick}>
                <input type='file' ref={addBannerInputRef} onChange={onAddBannerInputChanged} style={{display: 'none'}} />
                {bannersLoading ? <SkeletonRow /> : 
                    banners.length > 0 ? <BannerConfiguration banners={banners} onDelete={onDeleteBanner}/> : <NoItemsFound text='Nenhum banner encontrado' />
                }
            </ConfigurationSession>
            <ConfigurationSession title='Produtos' addClick={onProductAddClick}>
                <ProductsCategories onChange={onProductCategoryChange} value={productsCategory} />
                {productsLoading ? <SkeletonRow /> : 
                    viewProducts.length > 0 ? <ProductsConfiguration products={viewProducts} /> : 
                        <NoItemsFound text='Nenhum produto encontrado' /> 
                }
            </ConfigurationSession>
        </div>
    )
}

function TopBar(props){
    return(
        <div className={styles.TopBar}>
            <h2>{props.title}</h2>
            {props.logout ? <button onClick={props.logout}>Logout</button> : ''}
        </div>
    )
}

function SkeletonRow(){
    return(
        <div className={styles.SkeletonRow}>
            <Skeleton className={styles.SkeletonRowItem}/>
            <Skeleton className={styles.SkeletonRowItem}/>
            <Skeleton className={styles.SkeletonRowItem}/>
        </div>
    )
}

function NoItemsFound(props){
    return (
        <div className={styles.InternalConfigurationsNoItemsFound} >
            <h3>{props.text}</h3>
        </div>
    )
}

function ProductsCategories(props){
    function onCategoryChange(e){
        props.onChange(e.target.value)
    }
    return(
        <div className={styles.InternoConfigurationPageProductsCategories} onChange={onCategoryChange}>
            <input type='radio' id='InternoConfigurationPageProductsCategoriesTodos' name='InternoConfigurationPageProductsCategories' value='' checked={props.value === ''} />
            <label for='InternoConfigurationPageProductsCategoriesTodos'>Todos</label>
            <input type='radio' id='InternoConfigurationPageProductsCategoriesChinelo' name='InternoConfigurationPageProductsCategories' value='chinelo' checked={props.value === 'chinelo'} />
            <label for='InternoConfigurationPageProductsCategoriesChinelo'>Chinelo</label>
            <input type='radio' id='InternoConfigurationPageProductsCategoriesSandalia' name='InternoConfigurationPageProductsCategories' value='sandalia' checked={props.value === 'sandalia'} />
            <label for='InternoConfigurationPageProductsCategoriesSandalia'>Sandália</label>
            <input type='radio' id='InternoConfigurationPageProductsCategoriesSlider' name='InternoConfigurationPageProductsCategories' value='slider' checked={props.value === 'slider'} />
            <label for='InternoConfigurationPageProductsCategoriesSlider'>Slider</label>
        </div>
    )
}