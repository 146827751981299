import { getFirestore, setDoc, doc, getDoc } from "firebase/firestore";

export async function setMaintenance(value){
    const db = getFirestore()
    await setDoc(doc(db,'config','maintenance'), {inMaintenance: value})
}

export async function getMaintenance(){
    const db = getFirestore()
    const document = doc(db,'config','maintenance')
    var snapshot = await getDoc(document)
    var data = snapshot.data()
    return data.inMaintenance
}