import { getStorage, ref, listAll, getDownloadURL, getMetadata, deleteObject, uploadBytes } from "firebase/storage";

const path = 'Banners'

export async function getBannerImagesSrc(){
    
    try{
        
        const storage = getStorage();

        const listRef = ref(storage, path);

        let res = await listAll(listRef)

        let urls = []

        for(let item of res.items){
            let url = await getDownloadURL(item)
            urls.push(url)
        }

        return urls

    }catch(error){
        return []
    }
}

export async function getBanners(){
    try{
        const storage = getStorage()
        const listRef = ref(storage, path)
        let response = await listAll(listRef)
        var banners = []
        for(let item of response.items){
            let metadata = await getMetadata(item)
            let url = await getDownloadURL(item)
            let banner = {
                name: metadata.name,
                size: metadata.size,
                fullPath: metadata.fullPath,
                url: url
            }
            banners.push(banner)
        }
        return banners
    }catch(error){
        return false
    }
}

export async function deleteBanner(name){
    try{
        const storage = getStorage()
        const imageRef = ref(storage, `${path}/${name}`)
        await deleteObject(imageRef)
        return true
    }catch(error){
        return false
    }
}

export async function uploadBanner(file){
    try{
        const storage = getStorage()
        const bannerPathRef =  `${path}/${file.name}`
        console.log(`salvando arquivo em ${bannerPathRef}`)
        const bannerRef = ref(storage,bannerPathRef)
        await uploadBytes(bannerRef,file)
        return true
    }catch(error){
        return false
    }
}

export default getBannerImagesSrc