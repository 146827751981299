import { useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import ProductsCategoryProduct from "./components/product";
import styles from './index.module.css'
import { getProducts } from "../../services/products/product.service";
import { useEffect, useState } from "react";
import Skeleton from "../../components/Skeleton";

const titles = [
{
    code: 'sandalia',
    title: 'SANDÁLIAS'
},
{
    code: 'chinelo',
    title: 'CHINELOS'
},
{
    code: 'slider',
    title: 'SLIDERS'
}
]

export default function ProdutosCategoryPage(props){
    let params = useParams()

    const [isLoading, setIsLoading] = useState(true)
    const [products, setProducts] = useState([])

    let title = titles.find(t => t.code === params.category)?.title

    useEffect(() =>{
        getProducts(params.category)
        .then(productsFound => {
            console.log('products found:')
            console.log(productsFound)
            var newProducts = []
            for(var productFound of productsFound ){
                for(var color of productFound.colors){
                    newProducts.push({
                        id: productFound.id,
                        description: productFound.description,
                        category: productFound.category,
                        colorId: color.id,
                        color: color.color,
                        image: color.image
                    })
                }
            }
            console.log('new products:')
            console.log(newProducts)
            setProducts(newProducts)
            setIsLoading(false)
        })
        .catch(error =>{
            console.error(error)
            setIsLoading(false)
        })
    }, [])

    return(
        <div className={styles.ProdutosCategoryPage}>
            <div className={styles.ProdutosCategoryPageTitleContainer}>
                <h1>{title}</h1>
            </div>
            <div className={styles.ProdutosCategoryPageContent}>

                {products.map((product, index) => <ProductsCategoryProduct key={index} image={product.image} label={`${product.description} - ${product.color}`} />)}

                {products.length <= 0 && !isLoading ? <div className={styles.NoProducts}><h2>Nenhum produto encontrado</h2></div> : ''}

                {isLoading ? 
                <div className={styles.ProdutosCategoryPageContent}> 
                    <Skeleton className={styles.ProductsCategoryProductSkeleton} />
                    <Skeleton className={styles.ProductsCategoryProductSkeleton} />
                    <Skeleton className={styles.ProductsCategoryProductSkeleton} />
                    <Skeleton className={styles.ProductsCategoryProductSkeleton} />
                    <Skeleton className={styles.ProductsCategoryProductSkeleton} />
                    <Skeleton className={styles.ProductsCategoryProductSkeleton} />
                    <Skeleton className={styles.ProductsCategoryProductSkeleton} />
                    <Skeleton className={styles.ProductsCategoryProductSkeleton} />
                    <Skeleton className={styles.ProductsCategoryProductSkeleton} />
                </div> 
                : ''}

            </div>
            <Footer />
        </div>
    )
}