import styles from './index.module.css'
import Banner from '../../../../components/Banner/banner.module'
import bgImage from '../../../../assets/images/home/backgroundTop.png'
import {getBannerImagesSrc} from '../../../../services/bannerImages/bannerImages.service'
import { useEffect, useState } from 'react'

export default function HomeBanner(){

    const [bannerIndex, setBannerIndex] = useState(0)
    const [imagesSrc, setImagesSrc] = useState([])

    useEffect(() =>{
        console.log('getting banners')
        getBannerImagesSrc().then( urls =>{
            setImagesSrc(urls)
        })
    },[])

    useEffect(()=>{
        setTimeout(()=>{
            next()
        }, 2000)
    }, [bannerIndex, imagesSrc])

    function next(){
        if(bannerIndex >= (imagesSrc.length - 1)) setBannerIndex(0)
        else setBannerIndex(bannerIndex + 1)
    }

    return (
        <div className={styles.HomeBanner}>
            <img className={styles.backgroundImage} src={bgImage} alt='background'/>
            <Banner className={styles.Banner} images={imagesSrc ?? []} index={bannerIndex} />
        </div>
    )
}