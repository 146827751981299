import styles from './index.module.css'
import Logo from '../../../../assets/images/empresa/Logo.png'
import BackgroundEmpresaTop from '../../../../assets/images/empresa/backgroundEmpresaTop.png'
import BackgroundEmpresaBottom from '../../../../assets/images/empresa/backgroundEmpresaBottom.png'

export default function EmpresaComponent(){
    return(
        <div className={styles.EmpresaComponent}>
            <img id={styles.BackgroundEmpresaTop} src={BackgroundEmpresaTop} alt=''/>
            <div className={styles.EmpresaContentBackground}>
                <div className={styles.EmpresaContent}>
                    <h1>BEM-VINDO À</h1>
                    <a href='/'><img className={styles.EmpresaLogo} src={Logo} alt=''/></a>
                    <p>Estamos no mercado há mais de 10 anos como referência em injetado, garantindo a melhor qualidade na fabricação de nossos produtos, oferecendo agilidade, confiabilidade e comodidade aos nossos clientes.</p>
                    <p>
                        Trabalhamos com materiais de alta qualidade e buscamos aperfeiçoar cada vez mais os nossos produtos, investindo em maquinário mais moderno, equipamentos mais precisos, mão de obra qualificada e matéria prima de primeira linha.
                    </p>
                    <p>Hoje, oferecemos uma estrutura completa em injetados,solado em PVC, PVC expandido e micro expandido, palmilhas, sapatilhas, tiras, cabedais e enfeites. Nossa empresa oferece o primeiro passo para você começar o seu negócio com qualidade e comodidade! Seja um empreendedor de sucesso com a Fórmula!</p>
                    <h2>Quer saber mais?</h2>
                    <p>Deixe-nos um recado abaixo com suas dúvidas!</p>
                </div>
                
            </div>
            <img id={styles.BackgroundEmpresaBottom} src={BackgroundEmpresaBottom} alt=''/>
        </div>
    )
}