import emailjs, { init } from '@emailjs/browser'

export async function sendEmail(from_email, from_name, message){
    try{
    init('1nbPILmdzYtzwPjxh')

    const serviceId = 'service_ybn7jgr'
    const templateId = 'template_yvozodx'

    let params = {
        from_email: from_email,
        from_name: from_name,
        message: message
    }

    var response = await emailjs.send(serviceId, templateId, params)
    
    return !!(response);
    
    }catch(error){
        return false
    }
}