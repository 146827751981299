import { useState } from "react"
import styles from './configurationSession.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp, faCirclePlus } from '@fortawesome/free-solid-svg-icons'

export default function ConfigurationSession(props){
    const [hidden, setHidden] = useState(false)

    function onClick(){
        setHidden(!hidden)
    }

    return (
        <div className={styles.ConfigurationSession}>
            <SessionTopBar title={props.title} onClick={onClick} hidden={hidden} add={props.addClick ?? null}/>
            {hidden ? '' : props.children}
        </div>
    )
}

function SessionTopBar(props){
    return(
        <div className={styles.SessionTopBar} >
            <button className={styles.SessionTopBarLeft} onClick={props.onClick}>
                <span className={styles.SessionTopBarIcon} >
                    {props.hidden ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleUp} />}
                </span>
                <h3>{props.title}</h3>
            </button>
            {props.hidden ? null :<button className={styles.SessionTopBarAddButton} onClick={props.add ?? null}><FontAwesomeIcon icon={faCirclePlus} color='var(--main-red)' /></button>}
        </div>
    )
}