import { getAuth } from "firebase/auth"
import { useState } from "react"
import { LoginPage } from "./login/loginPage.module"
import InternoConfigurationPage from "./configurations"
import {login as authLogin, logout as authLogout} from '../../services/auth/auth.module'

export default function InternoPage(props){
    const [logged, setLogged] = useState(true)

    const auth = getAuth()

    auth.onAuthStateChanged(user =>{
        if(user){
            setLogged(true)
        }else{
            setLogged(false)
        }
    })

    function login(user, pass){
        if(user && pass){
            authLogin(user,pass)
        }
    }

    function logout(){
        authLogout()
    }

    return logged ? props.page : <LoginPage login={login}/>    
}