import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { 
  BrowserRouter,
  Routes,
  Route
 } from 'react-router-dom';
import Home from './pages/home'
import Empresa from './pages/empresa'
import Produtos from './pages/produtos'
import ProdutosCategoryPage from './pages/produtosCategory';
import { initFirebaseApp } from './services/firebaseService/firebase.module';
import InternoPage from './pages/interno';
import { ProductEditPage } from './pages/interno/productEditPage/productEditPage.module';
import InternoConfigurationPage from "./pages/interno/configurations"
import MaintenancePage from './pages/maintenance';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';

const rootElement = document.getElementById('root')

const root = ReactDOM.createRoot(rootElement)

const firebaseApp = initFirebaseApp

firebaseApp()

root.render(
  <Router />
);

function Router(){
  const [inMaintenance, setInMaintenance] = useState(false)

  useEffect(()=>{
    const db = getFirestore()
    onSnapshot(doc(db,'config','maintenance'), (document)=>{
      var data = document.data()
      setInMaintenance(data.inMaintenance)
    })
  }, [])
  
  return(
    <BrowserRouter>
      {inMaintenance ? 
      <Maintenance /> : 
      <MainRoutes />
      }
    </BrowserRouter>
  )
}

function MainRoutes(){
  return(
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/empresa' element={<Empresa />} />
      <Route path='/produtos' element={<Produtos />} />
      <Route path='/produtos/:category' element={<ProdutosCategoryPage />} />
      <Route path='/interno' element={<InternoPage page={<InternoConfigurationPage />} />} />
      <Route path='/edit-product' element={<InternoPage page={<ProductEditPage />} />} />
      <Route path='/edit-product/:productId' element={<InternoPage page={<ProductEditPage />} />} />
    </Routes>
  )
}

function Maintenance(){
  return(
    <Routes>
      <Route path='/' element={<MaintenancePage />} />
      <Route path='/interno' element={<InternoPage page={<InternoConfigurationPage />} />} />
      <Route path='/edit-product' element={<InternoPage page={<ProductEditPage />} />} />
      <Route path='/edit-product/:productId' element={<InternoPage page={<ProductEditPage />} />} />
    </Routes>
  )
}