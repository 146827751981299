import ContatoComponent from './components/contato'
import EmpresaComponent from './components/empresa'
import styles from './index.module.css'

export default function Empresa(props){
    return(
        <div className={styles.EmpresaPage}>
            <EmpresaComponent />
            <ContatoComponent />
        </div>
    )
}