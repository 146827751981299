import styles from './index.module.css'
import chinelosImage from '../../../../assets/images/chinelos.png'

export default function Bottom(props){
    return(
        <div className={styles.Bottom}>
            <img id={styles.chinelosImage} src={chinelosImage} alt=''/>
            <h1>Há mais de 10 anos fazendo histórias</h1>
            <p>Estamos no mercado há mais de 10 anos como referência em injetados, garantindo a melhor qualidade na fabricação de nossos produtos, oferecendo agilidade, confiabilidade e comodidade aos nossos clientes.</p>
            <h1 id={styles.BottomSlogan}>
                <span id={styles.cicleDecorator}>EXC</span>ELÊNCIA E AGILIDADE INJETADOS FORMULA
            </h1>
            <div className={styles.backgroundFix}></div>
        </div>
    )
}