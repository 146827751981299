import styles from './banner.module.css'
import { useEffect, useState } from 'react'
import Loading from '../LoadingDots/LoadingDots.module'

export default function Banner(props){

    const initialIndex = props.initialIndex ?? 0
    const [index, setIndex] = useState(initialIndex)

    useEffect(()=>{
        setIndex(props.index)
    },[props.index])

    return (
        <div className={styles.Banner + ' ' + props.className}>
            <Loading />
            {props.images.map((imageSrc, imageIndex) => <img key={imageIndex} className={index === imageIndex ? styles.active: (index === 0 && imageIndex === props.images.lenght - 1) || (imageIndex === index - 1) ? styles.lastActive : ''} src={imageSrc} loading="lazy"  alt=''/> )}
        </div>
    )
}