import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth'

export async function login(user, pass){
    const firebaseAuth = getAuth();
    return signInWithEmailAndPassword(firebaseAuth,user,pass)
}

export async function logout(){
    try{
        const firebaseAuth = getAuth()
        await signOut(firebaseAuth)
        return true
    }catch(error){
        return error
    }
}

const auth = {
    login: login,
    logout: logout
}

export default auth