import { useState } from 'react'
import styles from './loginPage.module.css'
import { login as authLogin } from '../../../services/auth/auth.module'
import Logo from '../../../assets/images/empresa/Logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export function LoginPage(props){
    
    const [user, setUser] = useState('')
    const [pass, setPass] = useState('')
    const [loginLoading, setLoginLoading] = useState(false)

    function onUserChange(e){
        setUser(e.target.value)
    }

    function onPassChange(e){
        setPass(e.target.value)
    }

    function login(){
        setLoginLoading(true)

        if(!user){
            alert('Usuário inválido!')
            setLoginLoading(false)
            return
        }

        if(!pass){
            alert('Senha inválida!')
            setLoginLoading(false)
            return
        }

        authLogin(user,pass).then(res =>{
            if(res.user){
                window.location.replace('/interno')
                setLoginLoading(false)
            }else{
                alert('Não foi possível fazer login!')
                setLoginLoading(false)
                return
            }
        }).catch(error => {
            alert('Usuário ou senha inválido! Não foi possível fazer login!')
            setLoginLoading(false)
            return
        })
        // setLoginLoading(false)
    }

    function enterPressed(e){
        if(e.keyCode === 13) login()
    }

    return(
        <div className={styles.LoginPage}>
            <img src={Logo} alt='Fórmula Logo'/>
            <input type='email' placeholder='digite aqui seu email' value={user} onChange={onUserChange} onKeyUp={enterPressed} />
            <input type='password' placeholder='digite aqui sua senha' value={pass} onChange={onPassChange} onKeyUp={enterPressed}/>
            <button onClick={login}>Login{loginLoading ? <span><FontAwesomeIcon icon={faSpinner} color='var(--main-red)' /></span> : null}</button>
        </div>
    )
}