import styles from './loadingDots.module.css'

export default function Loading(){
    return(
        <div className={styles.Loading}>
            <div class={styles.dotFloatingContainer}>
                <div class={styles.stage}>
                    <div class={styles.dotFloating}></div>
                </div>
            </div>
        </div>
    )
}